<template>
  <div class="content">
    <div class="main">
      <service-and-support-nav :active-index="2" />

      <div class="upView">
        <h3>線上教學</h3>
        <div class="introduce">
          針對產品如何正確使用與測量上的疑難雜症, 博銳可提供專業的線上教學服務.
          請於表格裡留下您的訊息, 我們將會迅速與您聯繫。
        </div>
      </div>
      <section class="oneView">
        <div class="title">聯絡訊息</div>
        <div class="formItem one">
          <form-slot label="尊稱" required>
            <el-select v-model="honored" placeholder="" clearable>
              <el-option label="女士" value="女士"></el-option>
              <el-option label="先生" value="先生"></el-option>
            </el-select>
          </form-slot>
          <form-slot label="姓" required>
            <el-input v-model="surname" clearable></el-input>
          </form-slot>
          <form-slot label="名" required>
            <el-input v-model="name" clearable></el-input>
          </form-slot>
        </div>
        <div class="formItem two">
          <form-slot label="電子郵箱" required>
            <el-input v-model="email" clearable></el-input>
          </form-slot>
          <form-slot label="電話" required>
            <el-input v-model="phone" clearable></el-input>
          </form-slot>
          <form-slot label="職務">
            <el-input v-model="position" clearable></el-input>
          </form-slot>
        </div>
        <form-slot label="公司全名" required>
          <el-input v-model="company_name" clearable></el-input>
        </form-slot>
        <form-slot label="公司地址" required>
          <el-input v-model="company_address" clearable></el-input>
        </form-slot>
        <form-slot label="其它訊息">
          <el-input
            v-model="other_messages"
            style="height: 96px"
            type="textarea"
            clearable
          ></el-input>
        </form-slot>
      </section>
      <section class="twoView">
        <div class="title">教學類別</div>
        <el-radio-group class="radio_group" v-model="teaching_category">
          <el-radio
            v-for="(item, index) in teaching_category_list"
            :key="index"
            :label="item.id"
            >{{ item.name }}</el-radio
          >
        </el-radio-group>
      </section>
      <section class="threeView">
        <div class="title">產品類別</div>
        <div class="formView">
          <form-slot label="產品型號" class="product_model">
            <el-select v-model="product_model" clearable>
              <el-option
                v-for="item in product_model_list"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </form-slot>
          <form-slot label="產品序列號" class="product_id">
            <el-input v-model="productId" clearable></el-input>
          </form-slot>
        </div>
      </section>
      <div class="agreementView">
        <el-checkbox v-model="agreement">我已閱讀並同意</el-checkbox>
        <router-link class="link" to="/privacy_policy">隱私權聲明</router-link>
      </div>
      <el-button
        type="primary"
        class="submit"
        @click="submit"
        :disabled="!agreement"
        round
        >送 出</el-button
      >
    </div>
  </div>
</template>

<script>
import ServiceAndSupportNav from "@/components/service-and-support/nav";
import formSlot from "@/components/slot/formView";
export default {
  name: "OnlineTeaching",
  components: {
    ServiceAndSupportNav,
    formSlot,
  },
  data() {
    return {
      honored: "",
      surname: "",
      name: "",
      email: "",
      phone: "",
      position: "",
      company_name: "",
      company_address: "",
      other_messages: "",
      /**教学类别 */
      teaching_category_list: [],
      teaching_category: "",
      /**产品型号 */
      product_model_list: [],
      product_model: "",
      productId: "",

      agreement: false,
    };
  },
  mounted() {
    this.productSelectListAllFun();
    this.onlineTeachingCateFun();
  },
  methods: {
    /**
     * 提交数据
     */
    submit() {
      let parameter = {
        honorific: this.honored,
        lastname: this.surname,
        firstname: this.name,
        email: this.email,
        tel: this.phone,
        job: this.position,
        company_name: this.company_name,
        company_address: this.company_address,
        other: this.other_messages,
        teaching_type: this.teaching_category,
        product_id: this.product_model,
        product_number: this.productId,
        type: 1,
      };

      if (parameter.honorific == "") {
        this.$message({
          message: "請選擇尊稱",
          type: "warning",
        });
        return;
      }
      if (parameter.lastname == "") {
        this.$message({
          message: "請輸入姓",
          type: "warning",
        });
        return;
      }
      if (parameter.firstname == "") {
        this.$message({
          message: "請輸入名",
          type: "warning",
        });
        return;
      }
      if (parameter.email == "") {
        this.$message({
          message: "請輸入電子郵箱",
          type: "warning",
        });
        return;
      }
      if (parameter.tel == "") {
        this.$message({
          message: "請輸入電話",
          type: "warning",
        });
        return;
      }
      if (parameter.company_name == "") {
        this.$message({
          message: "請輸入公司全名",
          type: "warning",
        });
        return;
      }
      if (parameter.company_address == "") {
        this.$message({
          message: "請輸入公司地址",
          type: "warning",
        });
        return;
      }
      this.$http.postData(parameter).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.honored = "";
          this.surname = "";
          this.name = "";
          this.email = "";
          this.phone = "";
          this.position = "";
          this.company_name = "";
          this.company_address = "";
          this.other_messages = "";
          this.teaching_category = "";
          this.product_model = "";
          this.productId = "";
          this.agreement = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    /**获取产品型号 */
    productSelectListAllFun() {
      this.$http.productSelectListAll().then((res) => {
        if (res.code == 200) {
          this.product_model_list = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    /**获取产品类别 */
    onlineTeachingCateFun() {
      this.$http.onlineTeachingCate().then((res) => {
        if (res.code == 200) {
          this.teaching_category_list = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.main {
  width: 1400px;
  margin: 0 auto;
  @include respond-to(max1439) {
    width: 95%;
  }
  @include respond-to(sm) {
    padding-bottom: 10px;
  }
  .upView {
    margin-bottom: 50px;
    h3 {
      font-size: 40px;
      color: #1a1a1a;
      font-weight: normal;
      margin-bottom: 20px;
      @include respond-to(lg) {
        font-size: 30px;
        margin-bottom: 10px;
      }
      @include respond-to(sm) {
        font-size: 16px;
      }
    }
    .introduce {
      font-size: 16px;
      color: #333;
      @include respond-to(lg) {
        font-size: 14px;
      }
      @include respond-to(sm) {
        font-size: 12px;
      }
    }
  }
  section {
    .title {
      padding-bottom: 10px;
      font-size: 20px;
      color: #1a1a1a;
      border-bottom: 1px solid #e6e6e6;
      margin-bottom: 30px;
    }
    /deep/ .form-item {
      margin-bottom: 32px;
      @include respond-to(sm) {
        margin-bottom: 20px;
      }
    }
    &.oneView {
      .formItem {
        display: flex;
        align-items: center;
        > * {
          margin-right: 50px;
          @include respond-to(sm) {
            margin-right: 20px;
          }
        }
        &.one {
          @include respond-to(sm) {
            flex-wrap: wrap;
          }
          /deep/ .form-item {
            width: 108px;
            @include respond-to(sm) {
              width: 100%;
              margin-right: 0;
              .el-select {
                width: 100%;
              }
            }
          }
        }
        &.two {
          @include respond-to(sm) {
            flex-wrap: wrap;
          }
          /deep/ .form-item {
            width: 300px;
            @include respond-to(sm) {
              width: 100%;
              margin-right: 0;
            }
          }
        }
      }
    }
    &.twoView {
      .radio_group {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        /deep/ .el-radio {
          margin-bottom: 15px;
        }
      }
    }
    &.threeView {
      .formView {
        display: flex;
        @include respond-to(sm) {
          flex-wrap: wrap;
        }
        .product_model {
          width: 300px;
          margin-right: 45px;
          @include respond-to(sm) {
            width: 100%;
            margin-right: 0;
          }
          .el-select {
            width: 100%;
          }
        }
        .product_id {
          width: 300px;
          @include respond-to(sm) {
            width: 100%;
            margin-right: 0;
          }
        }
      }
    }
  }
  .agreementView {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #333333;
    .link {
      font-size: 16px;
      color: #0075c1;
    }
  }
  .submit {
    display: block;
    width: 120px;
    margin: 70px auto 90px;
    @include respond-to(sm) {
      margin: 70px auto 30px;
    }
  }
}
</style>